import { WEBSOCKET_FIRST_CHANNEL } from '~/constants/app.constants';
import { parseWSData } from '~/utils/liveDrop/parseWSData';
import type {
  ILiveDropGetParams,
  ILiveDropWSResponse,
  TTopLiveDropData,
} from '~/project-layers/cs/features/live-drop/api/types/client.types';
import type { ITopLiveDropItem } from '~/utils/liveDrop/parseWSData.types';
import { useAlertStore } from '~/store/alert/alert.store';
import { ErrorCodes } from '~/api/global/errors/codes/codes';
import type { TPossibleNull } from '~/types/Shared.types';
import { LiveDropRepository } from '~/project-layers/cs/features/live-drop/api/repository/repository';
import { LIVE_ITEMS_MAX_LENGTH } from '../api/types/adapter';

const WEBSOCKET_EVENTS = ['lastwinner'];

export const MAX_ELEMENTS = 20;

export const useFeedStore = defineStore('feedStore/general', () => {
  const { $api } = useNuxtApp();

  const alertStore = useAlertStore();

  const isLoaded = ref(false);
  const liveItems = ref<TTopLiveDropData>({});
  const ultraItems = ref<TTopLiveDropData>({});
  const isPaused = ref(false);

  const fetch = async (params: ILiveDropGetParams = { isUltraRare: false }) => {
    try {
      const data = await LiveDropRepository.fetch(params);
      const toAdd = params.isUltraRare ? ultraItems : liveItems;
      toAdd.value = data;
    } catch (e) {
      alertStore.showError({
        message: (e as { msg: string | undefined }).msg || '',
        title: ErrorCodes.UNPREDICTED_EXCEPTION,
      });
    }
  };

  const getInitData = async () => {
    isLoaded.value = false;

    try {
      await Promise.all([fetch(), fetch({ isUltraRare: true })]);
    } catch {
    } finally {
      isLoaded.value = true;
    }
  };

  let unsubscribe: TPossibleNull<() => void> = null;

  const subscribe = () => {
    unsubscribe = $api.websocket.subscribe(WEBSOCKET_FIRST_CHANNEL, {
      cb: wsCallback,
      event: WEBSOCKET_EVENTS,
      uniqueKey: 'LiveDrops/CSDropItems',
    });
  };

  const wsCallback = (data: ILiveDropWSResponse) => {
    if (isPaused.value) return;
    const parsedMsg = parseWSData(JSON.parse(data.msg));

    const key = parsedMsg.gameName || `${parsedMsg.id}_${parsedMsg.id}`;
    const array = liveItems.value[key];

    const isAlreadyInArray = array?.find((item) => item.inventoryId?.toString() === parsedMsg.id);

    if (isAlreadyInArray) return;

    const newObj = { [key]: [parsedMsg] };

    if (array) array.push(parsedMsg);
    else liveItems.value = { ...newObj, ...liveItems.value };

    if (Object.keys(liveItems.value).length > LIVE_ITEMS_MAX_LENGTH) {
      liveItems.value = Object.fromEntries(Object.entries(liveItems.value).slice(0, LIVE_ITEMS_MAX_LENGTH));
    }
  };

  const handleMultiHover = (item: ITopLiveDropItem, hover = true) => {
    if (!item.isMulticastOrMultifix || !item.gameName) return;
    const key = item.gameName || item.id;

    if (!liveItems.value[key]) return;
    liveItems.value[key].forEach((i) => (i.hasMultiHover = hover));
  };

  return {
    fetch,
    getInitData,
    subscribe,
    handleMultiHover,
    unsubscribe,
    liveItems,
    ultraItems,
    isPaused,
    isLoaded,
  };
});

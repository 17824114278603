import {
  ETopLiveDropRarities,
  type ITopLiveDropItem,
  type ITopLiveDropModificator,
  type ITopLiveDropWSItem,
} from '~/utils/liveDrop/parseWSData.types';
import { Colors } from '~/constants/colors.constants';

export const isRarityCorrect = (rare: string) =>
  Object.values(ETopLiveDropRarities).includes(rare as ETopLiveDropRarities);

export const getModificatorOfItem = (percent: string, type: string): ITopLiveDropModificator | undefined => {
  const variantKey = type?.toLowerCase();
  if (!(variantKey in VARIANTS)) return undefined;

  const variant = VARIANTS[variantKey as keyof typeof VARIANTS];
  if (variantKey === 'upgrade' && percent) {
    const parsedFloat = parseFloat(percent);
    return {
      ...variant,
      text: `${+GlobalUtils.Numbers.toFixed(parsedFloat, 3)}%`,
    };
  }

  return variant;
};

const VARIANTS = {
  battle: {
    color: Colors.GRADIENTS.LIVEDROPBATTLES,
    icon: 'LazySvgoBattlesIcon',
  },
  contract: {
    color: Colors.GRADIENTS.LIVEDROPCONTRACT,
    icon: 'LazySvgoContractIcon',
  },
  upgrade: {
    color: Colors.GRADIENTS.LIVEDROPUPGRADE,
    icon: 'LazySvgoUpgradeIcon',
  },
};

export const getRarity = (quality: string) =>
  isRarityCorrect(quality.toLowerCase())
    ? (quality.toLowerCase() as ETopLiveDropRarities)
    : ETopLiveDropRarities.COMMON;

export const isMulticastOrMultifix = (type: string): boolean =>
  !!type?.includes('multifix') || !!type?.includes('multicast');

export const getDropType = (gameName: string, caseType: string) =>
  gameName?.toLowerCase().includes('battle') ? 'battle' : caseType;

export const parseWSData = (data: ITopLiveDropWSItem): ITopLiveDropItem => {
  return {
    id: data.id.toString(),
    images: {
      default: data.image,
      hover: data.caseimg,
    },
    isUltraRare: false,
    modificator: getModificatorOfItem(data.percent, getDropType(data.gamename, data.casetype)),
    rarity: getRarity(data.quality),
    quality: data.quality,
    user: {
      id: +data.userid,
      nickname: data.username,
    },
    case: {
      name: data.casename,
      type: data.casetype,
      price: data.caseprice,
    },
    additional: {
      percent: Number(data.percent) ? `${Number(data.percent).toFixed(2)} %` : '',
    },
    isMulticastOrMultifix: isMulticastOrMultifix(data.casetype),
    gameName: data.gamename,
    inventoryId: Number(data.id),
    type: data.casetype,
    name: data.name,
    price: Number(data.price),
  };
};
